import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyAChdq_wNBCHXnq9ZvEmbGDcu6s3dkAp0w",
  authDomain: "magic-dessert-5223a.firebaseapp.com",
  databaseURL: "https://magic-dessert-5223a-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "magic-dessert-5223a",
  storageBucket: "magic-dessert-5223a.appspot.com",
  messagingSenderId: "769978078882",
  appId: "1:769978078882:web:650b2fb1880a8ba6e869fb",
  measurementId: "G-2L6DPW774F"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "MAGIC DESSERT"; //公司名稱
      },
      get regFormDomain() {
        return "https://magic-dessert.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}